import Autocomplete from '@mui/material/Autocomplete';
import React from 'react';
import VehicleFilters from './Components/VehicleFilters';
import VehicleStatusGrid from './Components/VehicleStatusGrid';
import VehicleStatusList from './Components/VehicleStatusList';
import { CustomTextField, LegendTable, VehicleStatusWidgetViewContent } from './VehicleStatusWidget.style';
import { VehicleStatusWidgetViewProps } from './VehicleStatusWidget.type';
import UiLegend from 'components/Ui/Components/UiLegend/UiLegend';
import UiWidget from 'components/Ui/Components/UiWidget/UiWidget';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { ViewMode } from 'variables';
import { ReactComponent as HighPressureIcon } from 'assets/svg/pressureAlertHigh.svg';
import { ReactComponent as LowPressureIcon } from 'assets/svg/pressureAlertLow.svg';
import { applyStyleByMode } from 'helpers';
import { BACKGROUND_BLUE, RED_ICON, SECONDARY_DARK_MODE, WHITE } from '../../Ui/colors';
import { Theme } from 'states/global/Theme';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { faSlash as faSlashRegular } from '@fortawesome/pro-regular-svg-icons';
import { faThermometerThreeQuarters } from '@fortawesome/pro-solid-svg-icons';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

export const VehicleStatusWidgetContent: React.FC<VehicleStatusWidgetViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const themeMode = useRecoilValue(Theme);
    let heightWidget = 80;
    let heightUnitWidget: string | undefined = 'vh';
    const customerSettings = useRecoilValue(CustomerSettings);
    if (!props.isLgDevice) {
        heightWidget = props.viewMode === ViewMode.GRID ? 110 : 160;
        heightUnitWidget = undefined;
    }

    const LegendContent: React.FC = (): JSX.Element => (
        <LegendTable>
            <tbody>
                <tr>
                    <td>
                        <UiIcon
                            icon={['fas', 'square']}
                            size='lg'
                            fixedWidth
                            transform='left-2'
                            color={applyStyleByMode({
                                styleOld: 'rgb(113, 113, 113)',
                                theme: themeMode.mode,
                                dark: BACKGROUND_BLUE,
                                light: 'rgb(113, 113, 113)'
                            })}
                        />
                    </td>
                    <td>{translate('t.selected_vehicle')}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon
                            icon={['fas', 'square']}
                            size='lg'
                            fixedWidth
                            color='rgb(193, 193, 33)'
                            transform='left-2'
                        />
                    </td>
                    <td>{`${translate('t.vehicle_with_alert_level')} 1`}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon
                            icon={['fas', 'square']}
                            size='lg'
                            fixedWidth
                            color='rgb(234, 152, 0)'
                            transform='left-2'
                        />
                    </td>
                    <td>{`${translate('t.vehicle_with_alert_level')} 2`}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon
                            icon={['fas', 'square']}
                            size='lg'
                            fixedWidth
                            color='rgb(255, 0, 0)'
                            transform='left-2'
                        />
                    </td>
                    <td>{`${translate('t.vehicle_with_alert_level')} 3`}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon icon={['fas', 'wifi']} size='1x' fixedWidth color='rgb(21, 128, 21)' />
                    </td>
                    <td>{`${translate('t.transmitting_hub')}`}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon icon={['fas', 'wifi']} size='1x' fixedWidth color='#de9d0d' />
                    </td>
                    <td>
                        {`${translate('t.transmitting_hub')} ${translate('t.from_to', {
                            time1: `15 ${translate('t.minutes')}`,
                            time2: translate('t.1_day')
                        })}`}
                    </td>
                </tr>
                <tr>
                    <td>
                        <UiIcon icon={['fas', 'wifi-slash']} size='1x' fixedWidth color='gainsboro' />
                    </td>
                    <td>{`${translate('t.not_transmitting_hub')}`}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon icon={['fas', 'map-marker-alt-slash']} size='1x' fixedWidth color='orange' />
                    </td>
                    <td> {`${translate('t.not_transmitting_gps_first')}`}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon icon={['fas', 'map-marker-alt-slash']} size='1x' fixedWidth color='red' />
                    </td>
                    <td> {`${translate('t.not_transmitting_gps_second')}`}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon icon={['fas', 'map-marker-alt-slash']} size='1x' fixedWidth color='blue' />
                    </td>
                    <td> {`${translate('t.not_transmitting_gps_data')}`}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon
                            color={applyStyleByMode({
                                styleOld: '#000',
                                theme: themeMode?.mode,
                                light: SECONDARY_DARK_MODE,
                                dark: WHITE
                            })}
                            icon={['fas', 'window-close']}
                            size='1x'
                        />
                    </td>
                    <td>{`${translate('t.vehicle_without_hub')}`}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon
                            icon={['fas', 'wrench']}
                            size='1x'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#000',
                                theme: themeMode?.mode,
                                light: SECONDARY_DARK_MODE,
                                dark: WHITE
                            })}
                        />
                    </td>
                    <td> {`${translate('t.vehicle_in_maintenance')}`}</td>
                </tr>
                {/* Temporary disabled for BE */}
                {/* <tr>
                    <td>
                        <UiIcon
                            icon={['fas', 'parking']}
                            size='1x'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#000',
                                theme: themeMode?.mode,
                                light: SECONDARY_DARK_MODE,
                                dark: WHITE
                            })}
                        />
                    </td>
                    <td>{`${translate('t.parked_vehicle')}`}</td>
                </tr> */}
                <tr>
                    <td>
                        <UiIcon
                            icon={['fas', 'power-off']}
                            size='1x'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#000',
                                theme: themeMode?.mode,
                                light: SECONDARY_DARK_MODE,
                                dark: WHITE
                            })}
                        />
                    </td>

                    <td>{`${translate('t.hub_manually_turned_off')}`}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon
                            icon={['fas', 'battery-empty']}
                            size='1x'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#000',
                                theme: themeMode?.mode,
                                light: SECONDARY_DARK_MODE,
                                dark: WHITE
                            })}
                        />
                    </td>
                    <td>{`${translate('t.empty_battery')}`}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon
                            icon={['fas', 'battery-quarter']}
                            size='1x'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#000',
                                theme: themeMode?.mode,
                                light: SECONDARY_DARK_MODE,
                                dark: WHITE
                            })}
                        />
                    </td>
                    <td>{`${translate('t.battery_is_below')} 7 V`}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon
                            icon={['fas', 'battery-half']}
                            size='1x'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#000',
                                theme: themeMode?.mode,
                                light: SECONDARY_DARK_MODE,
                                dark: WHITE
                            })}
                        />
                    </td>
                    <td>{`${translate('t.battery_is_below')} 7.5 V`}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon
                            icon={['fas', 'battery-three-quarters']}
                            size='1x'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#000',
                                theme: themeMode?.mode,
                                light: SECONDARY_DARK_MODE,
                                dark: WHITE
                            })}
                        />
                    </td>
                    <td>{`${translate('t.battery_is_below')} 8 V`}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon
                            icon={['fas', 'battery-full']}
                            size='1x'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#000',
                                theme: themeMode?.mode,
                                light: SECONDARY_DARK_MODE,
                                dark: WHITE
                            })}
                        />
                    </td>
                    <td>{`${translate('t.full_battery')}`}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon
                            icon={['fas', 'truck']}
                            size='1x'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#000',
                                theme: themeMode?.mode,
                                light: SECONDARY_DARK_MODE,
                                dark: WHITE
                            })}
                        />
                    </td>
                    <td>{`${translate('t.active_vehicle')}`}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon icon={['fas', 'truck']} size='1x' fixedWidth color='rgb(200, 200, 200)' />
                    </td>
                    <td>{`${translate('t.out_of_service')}`}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon
                            icon={['fas', 'thermometer-three-quarters']}
                            size='1x'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#FF1616',
                                theme: themeMode?.mode,
                                dark: WHITE,
                                light: '#FF1616'
                            })}
                        />
                    </td>
                    <td>{`${translate('t.temperature_alert')}`}</td>
                </tr>
                <tr>
                    <td>
                        <span className='fa-layers fa-fw'>
                            <UiIcon
                                icon={faSlashRegular}
                                size='1x'
                                fixedWidth
                                color={applyStyleByMode({
                                    styleOld: '#FF1616',
                                    theme: themeMode?.mode,
                                    dark: WHITE,
                                    light: '#787878'
                                })}
                            />
                            <UiIcon
                                icon={faThermometerThreeQuarters}
                                size='1x'
                                fixedWidth
                                color={applyStyleByMode({
                                    styleOld: '#FF1616',
                                    theme: themeMode?.mode,
                                    dark: WHITE,
                                    light: '#787878'
                                })}
                            />
                        </span>
                    </td>
                    <td>{`${translate('t.temperature_alert_disabled')}`}</td>
                </tr>
                {customerSettings.temperature_prediction && (
                    <tr>
                        <td>
                            <UiIcon
                                icon={['fas', 'chart-mixed-up-circle-currency']}
                                size='1x'
                                fixedWidth
                                color={applyStyleByMode({
                                    styleOld: '#FF1616',
                                    theme: themeMode?.mode,
                                    dark: WHITE,
                                    light: '#FF1616'
                                })}
                            />
                        </td>
                        <td>{translate('t.has_temperature_prediction')}</td>
                    </tr>
                )}
                {customerSettings.temperature_prediction && (
                    <tr>
                        <td>
                            <span className='fa-layers fa-fw'>
                                <UiIcon
                                    size='1x'
                                    icon={['fas', 'slash']}
                                    color={applyStyleByMode({
                                        theme: themeMode?.mode,
                                        light: '#0000008a',
                                        dark: WHITE
                                    })}
                                />
                                <UiIcon
                                    icon={['fas', 'p']}
                                    size='1x'
                                    fixedWidth
                                    color={applyStyleByMode({
                                        theme: themeMode?.mode,
                                        dark: WHITE,
                                        light: '#0000008a'
                                    })}
                                />
                            </span>
                        </td>
                        <td>{translate('p.temperature_prediction_not_working')}</td>
                    </tr>
                )}
                <tr>
                    <td>
                        <LowPressureIcon style={{ width: 15, height: 17, marginRight: 0 }} />
                    </td>
                    <td>{`${translate('t.low_pressure_alert')}`}</td>
                </tr>
                <tr>
                    <td>
                        <HighPressureIcon style={{ width: 15, height: 17, marginRight: 0 }} />
                    </td>
                    <td>{`${translate('t.high_pressure_alert')}`}</td>
                </tr>
                <tr>
                    <td>
                        <UiIcon
                            icon={['fas', 'triangle-exclamation']}
                            size='1x'
                            fixedWidth
                            color={applyStyleByMode({
                                theme: themeMode?.mode,
                                light: RED_ICON,
                                dark: RED_ICON
                            })}
                        />
                    </td>
                    <td>{translate('t.system_alerts')}</td>
                </tr>
            </tbody>
        </LegendTable>
    );

    return (
        <VehicleStatusWidgetViewContent>
            <UiWidget
                title={translate('t.vehicle_status')}
                avatar={
                    <UiIcon
                        icon={['fas', 'car-wrench']}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: themeMode?.mode,
                            light: SECONDARY_DARK_MODE,
                            dark: WHITE
                        })}
                    />
                }
                loading={props.isLoading}
                $contentHeight={heightWidget}
                unit={heightUnitWidget}
                scrollable={1}
                collapse={false}
                marginBottom={false}
                headerAction={
                    <>
                        <Autocomplete
                            id='vehicle-search'
                            size='small'
                            options={props.data ? props.data.vehicleStatus : []}
                            getOptionLabel={(option) => option.vehicle.name}
                            renderOption={(props, option) => {
                                return <li {...props} key={props.id}>{`${option.vehicle.name}`}</li>;
                            }}
                            renderInput={(params) => (
                                <CustomTextField
                                    {...params}
                                    placeholder={translate('t.search')}
                                    variant='outlined'
                                    data-testid='VehicleStatus-VehicleSearch-AutoComplete'
                                />
                            )}
                            onChange={(event, value) => {
                                value && props.handleFilterByVehicle(value.vehicle.id);
                            }}
                        />

                        <UiIconButton
                            onClick={() => props.handleFilterToggle()}
                            size='large'
                            testid='VehicleStatus-ShowFilter-Button'
                        >
                            <UiIcon
                                icon={['fas', 'bars-filter']}
                                fontSize={14}
                                fixedWidth
                                color={applyStyleByMode({
                                    styleOld: '#000',
                                    theme: themeMode?.mode,
                                    light: '#0000008a',
                                    dark: WHITE
                                })}
                            />
                        </UiIconButton>
                        <UiIconButton
                            onClick={() => props.handleChangeView()}
                            size='large'
                            testid='VehicleStatus-ShowModeGridOrList-Button'
                        >
                            {!props.viewMode || props.viewMode === ViewMode.GRID ? (
                                <UiIcon
                                    icon={['fas', 'list']}
                                    fontSize={14}
                                    fixedWidth
                                    color={applyStyleByMode({
                                        styleOld: '#000',
                                        theme: themeMode?.mode,
                                        light: '#0000008a',
                                        dark: WHITE
                                    })}
                                />
                            ) : (
                                <UiIcon
                                    icon={['fas', 'grid']}
                                    fontSize={14}
                                    fixedWidth
                                    color={applyStyleByMode({
                                        styleOld: '#000',
                                        theme: themeMode?.mode,
                                        light: '#0000008a',
                                        dark: WHITE
                                    })}
                                />
                            )}
                        </UiIconButton>
                        <UiLegend
                            title={translate('t.legend')}
                            content={<LegendContent />}
                            minWidth={325}
                            width={350}
                        />
                    </>
                }
                content={
                    <>
                        {props.data ? (
                            <>
                                <VehicleFilters />
                                {!props.viewMode || props.viewMode === ViewMode.GRID ? (
                                    <VehicleStatusGrid {...props} />
                                ) : (
                                    <VehicleStatusList {...props} />
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                }
            />
        </VehicleStatusWidgetViewContent>
    );
};
