import { useQuery } from '@tanstack/react-query';
import VehicleModel from 'api/VehicleModel';
import VehicleManufacturer from 'api/VehicleManufacturer';
import Device from 'api/Device';
import VehicleConfigurationApi from 'api/VehicleConfiguration';
import VehicleTypeApi from 'api/VehicleType';
import { Manufacturer, VehicleManufacturerType } from 'models/VehicleManufacturer.type';
import { VehicleModelType } from 'models/VehicleModel.type';
import { DeviceQueryKeys, DeviceType } from 'models/Device.type';
import { QueriesTypes } from './VehicleActions.type';
import { VehicleTypeQueryKeys, VehicleTypesCodeBook } from 'models/VehicleType.type';
import { VehicleConfigurationQueryKeys, VehicleConfigurationsType } from 'models/VehicleConfiguration.type';
import { useRecoilValue } from 'recoil';
import { ShowVehicleModal } from 'states/global/Modal';
import ExternalSystemAPI from 'api/ExternalSystem';
import { VehicleQueryKeys } from 'models/Vehicle.type';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { ExternalSystemQueryKeys } from 'models/ExternalSystem.type';

const vehicleManufacturerAPI = new VehicleManufacturer();
const vehicleModelAPI = new VehicleModel();
const deviceAPI = new Device();
const vehicleConfigurationAPI = new VehicleConfigurationApi();
const vehicleTypeAPI = new VehicleTypeApi();
const externalSystemAPI = new ExternalSystemAPI();

export const useVehicleActionsQueries = (): QueriesTypes => {
    const modalIsOpen = useRecoilValue(ShowVehicleModal);
    const vehicleModelData = useQuery<VehicleModelType>(
        [VehicleQueryKeys.getVehicleModel],
        () => vehicleModelAPI.get({}),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            enabled: modalIsOpen,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours')
        }
    );
    const vehicleManufacturerData = useQuery<Manufacturer[]>(
        [VehicleQueryKeys.getVehicleManufacturer],
        () => vehicleManufacturerAPI.getCodebook({}),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            enabled: modalIsOpen,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours')
        }
    );
    const availableDevices = useQuery<DeviceType>(
        [DeviceQueryKeys.getAvailableDevices],
        () => deviceAPI.getAvailableDevices(),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            staleTime: cacheTimeToMilliseconds(5, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(5, 'minutes'),
            enabled: modalIsOpen
        }
    );
    const vehicleTypes = useQuery<VehicleTypesCodeBook>(
        [VehicleTypeQueryKeys.getCodebook],
        () => vehicleTypeAPI.getCodebook({ version: 'v2' }),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            enabled: modalIsOpen,
            staleTime: cacheTimeToMilliseconds(55, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(60, 'minutes')
        }
    );
    const vehicleConfiguration = useQuery<VehicleConfigurationsType>(
        [VehicleConfigurationQueryKeys.getV2],
        () => vehicleConfigurationAPI.get({ version: 'v2' }),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            enabled: modalIsOpen,
            staleTime: 0,
            cacheTime: 0
        }
    );

    const externalSystems = useQuery([ExternalSystemQueryKeys.getCurrent], () => externalSystemAPI.getCurrent(), {
        refetchOnWindowFocus: false,
        refetchInterval: false,
        enabled: modalIsOpen,
        staleTime: 0,
        cacheTime: 0
    });

    const loading: boolean =
        vehicleModelData.isLoading &&
        vehicleManufacturerData.isLoading &&
        availableDevices.isLoading &&
        vehicleTypes.isLoading &&
        vehicleConfiguration.isLoading &&
        externalSystems.isLoading;

    return {
        vehicleModelData: vehicleModelData.data,
        vehicleManufacturerData: vehicleManufacturerData.data,
        availableDevices: availableDevices,
        vehicleTypes: vehicleTypes.data,
        vehicleConfiguration: vehicleConfiguration.data,
        externalSystems: externalSystems.data,
        isLoading: loading
    };
};
